import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
export default function dateTypeListOptions() {
    
	const dateTypeOptions = [{
        id: 'today',
        text: 'Today'
    }, {
        id: 'yesterday',
        text: 'Yesterday'
    }, {
        id: 'last7days',
        text: 'Last 7 days'
    }, {
        id: 'lastweek',
        text: 'Last week'
    }, {
        id: 'thismonth',
        text: 'This month'
    }, {
        id: 'last30days',
        text: 'Last 30 days'
    }, {
        id: 'lastmonth',
        text: 'Last month'
    }, {
        id: 'last90days',
        text: 'Last 90 days'
    }, {
        id: 'custom_date',
        text: 'Custom Date'
    }, {
        id: 'all_time',
        text: 'All Time'
    }];

    return {
        dateTypeOptions
    }
}